<template>
    <div>

        <!-- Модальное окно при отзыве сертификата -->
        <div class="modal is-active" v-if="revokeModalActive">
            <div class="modal-background" v-on:click="openRevokeModal"></div>
            <div class="modal-card">
                <header class="modal-card-head">
                    <p class="modal-card-title">{{ $t('Revoke a digital signature certificate') }}</p>
                </header>
                <section class="modal-card-body">
                    <div class="field">
                        <label class="label">{{ $t('Enter your order number') }}*</label>
                        <div class="control">
                            <input v-model.trim="order" name="order" v-validate="'required|orderRevoke'" data-vv-validate-on="'change'" class="input" :class="{'has-error': errors.has('order')}"  type="text" placeholder="">
                            <p v-if="errors.has('order')" class="alert-error ">{{errors.first('order')}}</p>
                        </div>
                        <p class="help">{{ $t('Please enter the number of the order your certificate was issued with') }}.</p>
                    </div>
                </section>
                <footer class="modal-card-foot">
                    <button class="button is-primary" type="button" v-on:click="GoToRevoke">{{ $t('Revoke a digital signature certificate') }}</button>
                    <a class="button" v-on:click="openRevokeModal">{{ $t('Cancel') }}</a>
                </footer>
            </div>
        </div>
        <!-- Заголовок -->

        <section class="section">
            <div class="container has-text-centered">
                <h1 class="title is-3 is-marginless">{{ $t('Revocation of the EDS certificate') }}</h1>
            </div>
            <div style="height: 3rem;"></div>

            <!-- Контент -->
            <div class="container">
                <div style="height:0.75rem;"></div>
                <div class="content">
                    <div class="columns is-centered">
                        <div class="column is-8 card is-bgr-silver">
                            <h6 class="has-text-centered">{{ $t('in case of') }}:</h6>
                            <div style="height:1rem;"></div>
                            <div class="columns">
                                <div class="column has-text-centered">
                                    <figure class="image is-96x96 is-center"><img src="@/assets/icons/discredit_usb.svg" style="height: 90px"></figure>
                                    - {{ $t('digital signature key discredit') }};
                                </div>
                                <div class="column has-text-centered">
                                    <figure class="image is-96x96 is-center"><img src="@/assets/icons/clipboards.svg" style="height: 75px"></figure>
                                    - {{ $t('changing the details of the organization') }};
                                </div>
                                <div class="column has-text-centered">
                                    <figure class="image is-96x96 is-center"><img src="@/assets/icons/edit_person.svg" style="height: 86px"></figure>
                                    - {{ $t('a change of the authorized person') }}.
                                </div>
                            </div>
                            <div style="height:0.5rem;"></div>
                        </div>
                    </div>
                    <div style="height:3rem;"></div>
                    <div class="columns is-centered">
                        <div class="column is-8">
                            <div style="height:1rem;"></div>
                            <div class="columns is-centered">
                                <div class="column" v-if="template">
<!--                                    <pre>{{template}}</pre>-->
                                    <p class="currency">{{ $t('Value') }}: <b>{{pickedCurrency}} {{currencyType}}</b> &ensp;
                                        (
                                        <label class="radio currency">
                                            <input class="currency" type="radio" v-bind:value="template.revoke_kzt" v-model="pickedCurrency" @click="updatePrice(template.revoke_kzt, 'KZT')">
                                            KZT
                                        </label>
                                        <label class="radio currency">
                                            <input class="currency" type="radio" v-bind:value="template.revoke_usd" v-model="pickedCurrency" @click="updatePrice(template.revoke_usd, 'USD')">
                                            USD
                                        </label>
                                        <label class="radio currency">
                                            <input class="currency" type="radio" v-bind:value="template.revoke_rur" v-model="pickedCurrency" @click="updatePrice(template.revoke_rur, 'RUB')">
                                            RUB
                                        </label>
                                        <label class="radio currency">
                                            <input class="currency" type="radio" v-bind:value="template.revoke_eur" v-model="pickedCurrency" @click="updatePrice(template.revoke_eur, 'EUR')">
                                            EUR
                                        </label>
                                        )
                                    </p>
                                </div>
                                <div class="column is-3 is-right">
                                    <input class="button is-success is-large is-mobile-small" type="button" v-on:click="openRevokeModal" v-bind:value="$t('Apply')">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style="height:2rem;"></div>
            </div>

        </section>

    </div>
</template>

<script>
export default {
  name: 'GetCertificate',
  data () {
    return {
      order: null,
      revokeModalActive: false,
      currencies: null,
      pickedCurrency: 16100,
      templates: null,
      countries: null,
      selectedCountryId: null,
      currencyType: 'KZT',
      myTemplate: {},
      visible:true
    }
  },
  computed: {
    lang () {
      return this.$i18n.locale
    },
    template () {
      return this.templates ? this.templates.find(item => item.template_name === 'SAMRUK_1Y') : null
    },
    currentCountry () {
      return this.countries ? this.countries.find(item => item.id === this.selectedCountryId) : null
    },
    selectedCountryShortName () {
      return this.currentCountry ? this.currentCountry.shortname : null
    }
  },
  watch: {
    countries: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/countries/').then(response => {
            this.countries = response.data
            this.$http.get('/query/get-country/').then(response => {
              if (response.data) {
                const country = this.countries ? this.countries.find(item => item.name === response.data) : null
                this.selectedCountryId = country.id
              } else {
                this.selectedCountryId = '2'
              }
            })
          })
        }
      },
      immediate: true
    },
    templates: {
      handler: function (val) {
        if (!val) {
          this.$http.get('/query/templates/').then(response => {
            this.templates = response.data
          })
        }
      },
      immediate: true
    }
  },
  methods: {
    updatePrice (price, currency) {
      this.currencyType = currency
    },
    openRevokeModal () {
      this.revokeModalActive = !this.revokeModalActive
    },
    GoToRevoke () {
      this.$validator.validateAll().then((result) => {
        if (result) {
          let tempCurrencyId = 4
          if (this.currencyType == 'RUB') {
            tempCurrencyId = 6
          } else if (this.currencyType == 'EUR') {
            tempCurrencyId = 7
          } else if (this.currencyType == 'USD') {
            tempCurrencyId = 5
          } else {
            tempCurrencyId = 4
          }

          this.$store.commit('updateCurrency', this.currencyType)
          this.$store.commit('updateCurrencyId', tempCurrencyId)
          this.$store.commit('updatePrice', this.pickedCurrency)
          this.$store.commit('updateTemplate', this.myTemplate.template_name)
          this.$store.commit('updateTemplateId', this.myTemplate.id)
          this.$store.commit('updateTemplateName', (this.lang === 'ru' ? this.myTemplate.description : this.myTemplate.description_en))
          this.$store.commit('updateTemplateData', this.myTemplate.template)
          this.$store.commit('updateTemplateValidity', (this.lang === 'ru' ? this.myTemplate.validity : this.myTemplate.validity_en))
          this.$store.commit('updateOrder', this.order)
          this.$router.push('/revoke')
        }
      })
    }
  },
  created () {
    const isValidRevoke = (value) => {
      return this.$http.get('/query/get-order-info/', { params: { order: value, type: 'revoke' } }).then((response) => {
        this.myTemplate = response.data
        this.$store.commit('updateMyTemplate', this.myTemplate)

        if (response.data) {
          return true
        } else return false
      })
    }
    this.$validator.extend('orderRevoke', {
      validate: isValidRevoke
    })
  }
}
</script>

<style scoped lang="scss">
    h5 {
        text-align: center;
    }
    .right {
        margin-left: 30px;
    }
    .is-center {
        margin: auto;
    }
    .is-right {
        text-align: right;
    }
    .is-bgr-silver {
        background-color: #f7f7f7;
    }
    .is-large {
        font-size: 1.2rem;
    }
    .card-column-content {
        padding: 0 0.8rem 2rem 1rem;
    }
    .currency {
        padding-top: 8px;
    }
    .has-error {
        border-color: red;
    }
    .alert-error {
        color: red;
    }
</style>
